import { Script } from "gatsby-script"
import React from "react"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {/* <Script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WJ5GSML');`}</Script> */}
      {/* <Script src="https://js.na.chilipiper.com/marketing.js" /> */}
      {/* <Script src="//js.hsforms.net/forms/v2.js" /> */}
      <Script src="/load.js" />
    </>
  )
}
